import React, { Fragment, useState } from 'react';
import { Loader, useAPI, Pdftest } from 'components/lib';
import Pdf from 'react-to-pdf';

export function PdfView (props) {
  const [state, setState] = useState({});
  const data = useAPI('/api/pdfView');

  if (!data) { return false; }

  return (
    <>
      <Pdftest />
    </>

  );
}
