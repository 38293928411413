import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useAPI, ViewContext, Card, Button, Table, Animate, TitleRow } from 'components/lib';

export function Services (props) {
  const list = useAPI('/api/services');
  const [items, setItems] = useState([]);
  const context = useContext(ViewContext);

  useEffect(() => {
    console.log('triggeer');
    if (list?.data?.length) {
      console.log(list.data);
      setItems(list.data);
    }
  }, [list.data]);

  const addItem = () => {
    context.modal.show({
      title: 'Add Services',
      form: {
        name: {
          type: 'text',
          label: 'Service Name',
          required: true
        },
        description: {
          type: 'text',
          label: 'Description',
          required: false
        },
        price: {
          type: 'number',
          label: 'Price',
          required: true
        }
      },
      buttonText: 'Save Services',
      url: '/api/services',
      method: 'POST'
    }, (form, res) => {
      context.notification.show('Service was added', 'success', true);
      const x = [...items];
      x.push({
        id: res._id,
        name: res.name,
        description: res.description,
        price: res.price
      });
      console.log(x);
      setItems(x);
    });
  };

  const editItem = (data, callback) => {
    console.log(data);
    context.modal.show({
      title: 'Update Service',
      form: {
        id: {
          type: 'hidden',
          value: data.id
        },
        name: {
          type: 'text',
          label: 'Service Name',
          value: data.name,
          required: true
        },
        description: {
          type: 'text',
          label: 'Description',
          value: data.description,
          required: false
        },
        price: {
          type: 'number',
          label: 'Price',
          value: data.price,
          required: false
        }
      },
      buttonText: 'Update Service',
      url: '/api/services/' + data._id,
      method: 'PATCH'

    }, (res) => {
      context.notification.show(data.name + ' was updated', 'success', true);
      callback(res);
    });
  };

  const deleteItem = (data, callback) => {
    context.modal.show({
      title: 'Delete Service',
      form: {
        id: {
          type: 'hidden',
          value: data._id
        }
      },
      buttonText: 'Delete Service',
      text: `Are you sure you want to delete ${data.name}?`,
      url: '/api/services/' + data._id,
      method: 'DELETE'

    }, () => {
      context.notification.show(data.name + ' was deleted', 'success', true);
      callback();
    });
  };

  return (
    <>

      {/* render view data */}
      <Animate>

        <TitleRow title='Manage Services'>
          <Button small text='Add Service' action={addItem} />
        </TitleRow>

        <Card>
          <Table
            search
            className='restrict-width'
            data={items}
            loading={list?.loading}
            show={['name', 'price', 'date_created']}
            actions={{ edit: editItem, delete: deleteItem }}
          />
        </Card>

      </Animate>
    </>

  );
}
