/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import React from 'react';
import { Animate, Row, Content } from 'components/lib';
import Style from './footer.tailwind.js';

export function Footer (props) {
  return (
    <Animate type='slideup'>
      <footer className={Style.footer}>
        <Row>
          <Content>
            <div className={Style.copyright}>
              {`Copyright © JV Systems ${new Date().getFullYear()}`}
            </div>
            <address className={Style.address}>Mutual Homes Davao City</address>
          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
