import React, { Fragment, useState, useContext, useEffect } from 'react';
import {
  useAPI,
  ViewContext,
  Card,
  Button,
  Table,
  Animate,
  TitleRow
} from 'components/lib';

export function Customers (props) {
  const list = useAPI('/api/customers');
  const [items, setItems] = useState([]);
  const context = useContext(ViewContext);

  useEffect(() => {
    console.log('triggeer');
    if (list?.data?.length) {
      console.log(list.data);
      setItems(list.data);
    }
  }, [list.data]);

  const addItem = () => {
    context.modal.show(
      {
        title: 'Add Item',
        form: {
          name: {
            type: 'text',
            label: 'Customer Name',
            required: true
          },
          address: {
            type: 'text',
            label: 'Address',
            required: false
          },
          description: {
            type: 'text',
            label: 'Description',
            required: false
          }
        },
        buttonText: 'Save Customer',
        url: '/api/customers',
        method: 'POST'
      },
      (form, res) => {
        context.notification.show('Customer was added', 'success', true);
        const x = [...items];
        x.push({
          id: res._id,
          name: res.name,
          description: res.description,
          address: res.address,
          date_created: res.date_created
        });
        setItems(x);
      }
    );
  };

  const editItem = (data, callback) => {
    console.log(data);
    context.modal.show(
      {
        title: 'Update Customer',
        form: {
          id: {
            type: 'hidden',
            value: data.id
          },
          name: {
            type: 'text',
            label: 'Customer Name',
            value: data.name,
            required: true
          },
          address: {
            type: 'text',
            label: 'Address',
            value: data.address,
            required: false
          },
          description: {
            type: 'text',
            label: 'Description',
            value: data.description,
            required: false
          }
        },
        buttonText: 'Update Customer',
        url: '/api/customers/' + data._id,
        method: 'PATCH'
      },
      (res) => {
        context.notification.show(data.name + ' was updated', 'success', true);
        callback(res);
      }
    );
  };

  const deleteItem = (data, callback) => {
    context.modal.show(
      {
        title: 'Delete Customer',
        form: {
          id: {
            type: 'hidden',
            value: data._id
          }
        },
        buttonText: 'Delete Customer',
        text: `Are you sure you want to delete ${data.name}?`,
        url: '/api/customers/' + data._id,
        method: 'DELETE'
      },
      () => {
        context.notification.show(data.name + ' was deleted', 'success', true);
        // callback();
      }
    );
  };

  return (
    <>
      <Animate>
        <TitleRow title='Manage Customers'>
          <Button small text='Add Customer' action={addItem} />
        </TitleRow>

        <Card>
          <Table
            search
            className='restrict-width'
            data={items}
            loading={list?.loading}
            show={['name', 'address', 'date_created']}
            actions={{ edit: editItem, delete: deleteItem }}
          />
        </Card>
      </Animate>
    </>
  );
}
