import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Loader,
  useAPI,
  Card,
  Label,
  TextInput,
  TitleRow,
  Button,
  ViewContext,
  PriceTotal,
  useNavigate,
  useLocation,
  Select,
  Grid
} from 'components/lib';
import _ from 'lodash';
import axios from 'axios';
import printBilling from 'common/printBilling';
import DataTable from 'react-data-table-component';

export function CopyInvoice (props) {
  const context = useContext(ViewContext);
  const navigate = useNavigate();

  const loc = useLocation();

  const [loading, setLoading] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [selectedCustomer, setCustomer] = useState({});
  const [servicesList, setServicesList] = useState([]);
  const [itemData, setItemData] = useState([]);

  const [invoiceNo, setInvoiceNo] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');
  const [invoiceData, setInvoiceData] = useState('');
  const [consignee, setConsignee] = useState('');
  const [purpose, setPurpose] = useState('');
  const [commodity, setCommodity] = useState('');
  const [bol, setBol] = useState('');
  const [noc, setNoc] = useState('');
  const [ata, setAta] = useState('');
  const [container, setContainer] = useState('');
  const [vessel, setVessel] = useState('');

  const customers = useAPI('/api/customers');
  const services = useAPI('/api/services');

  const queryString = loc.search;
  const params = new URLSearchParams(queryString);

  const columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Or/No',
      selector: 'orno',
      sortable: true
    },
    {
      name: 'Rate',
      selector: 'rate',
      sortable: true
    },
    {
      name: 'Action',
      button: true,
      cell: (row) => {
        return (
          <div>
            <Button icon='edit' action={() => editItem(row)} /> &nbsp;&nbsp;
            <Button icon='trash' action={() => deleteItem(row)} />
          </div>
        );
      }
    }
  ];

  const invoices = useAPI('/api/invoices/' + params.get('id'), 'GET');

  useEffect(() => {
    console.log('customer data');
    if (customers?.data?.length) {
      console.log(customers.data);

      const customersDDL = customers.data.map((d) => {
        return { value: d._id, label: d.name };
      });
      setCustomersList(customersDDL);
    }
  }, [customers.data]);

  useEffect(() => {
    console.log('services data');
    if (services?.data?.length) {
      console.log(services.data);

      const servicesDDL = services.data.map((d) => {
        return { value: d._id, label: d.name };
      });
      setServicesList(servicesDDL);
    }
  }, [services.data]);

  useEffect(() => {
    console.log('invoice data');
    if (invoices?.data?.length) {
      console.log(invoices.data);

      setCustomer(invoices.data[0].customer);
      setInvoiceNo(invoices.data[0].invoiceNumber);
      setInvoiceDate(invoices.data[0].invoiceDate);
      setConsignee(invoices.data[0].consignee);
      setCommodity(invoices.data[0].commodity);
      setPurpose(invoices.data[0].subject);
      setBol(invoices.data[0].bol);
      setNoc(invoices.data[0].noc);
      setAta(invoices.data[0].ata);
      setVessel(invoices.data[0].vessel);
      setContainer(invoices.data[0].container);
      setItemData(
        invoices.data[0].items.map((e) => ({
          ...e,
          _id: 'id' + Math.floor(Math.random() * 1000)
        }))
      );
      setInvoiceData(invoices.data[0]);
    }
  }, [invoices.data]);

  const handleInvoiceNoChange = (name, value, valid) => {
    setInvoiceNo(value);
  };
  const handleInvoiceDateChange = (name, value, valid) => {
    setInvoiceDate(value);
  };
  const handleConsignee = (name, value, valid) => {
    setConsignee(value);
  };
  const handlePurpose = (name, value, valid) => {
    setPurpose(value);
  };
  const handleCommodity = (name, value, valid) => {
    setCommodity(value);
  };
  const handleBol = (name, value, valid) => {
    setBol(value);
  };
  const handleNoc = (name, value, valid) => {
    setNoc(value);
  };
  const handleAta = (name, value, valid) => {
    setAta(value);
  };
  const handleVessel = (name, value, valid) => {
    setVessel(value);
  };
  const handleContainer = (name, value, valid) => {
    setContainer(value);
  };

  const handleSelectChange = (name, value, valid) => {
    const c = customers.data.find((d) => d._id === value);
    setCustomer(c);
  };

  const addItem = () => {
    context.modal.show(
      {
        title: 'Add Services',
        form: {
          name: {
            type: 'select',
            options: servicesList,
            label: 'Service Name',
            required: true
          },

          orno: {
            type: 'text',
            label: 'OR/s',
            required: false
          },
          price: {
            type: 'number',
            label: 'Price',
            required: true
          }
        },
        buttonText: 'Save Services',
        url: '/api/newInvoice/dummy',
        method: 'POST'
      },
      (form, res) => {
        const c = services.data.find((d) => d._id === form.name.value);
        const x = [...itemData];
        x.push({
          _id: 'id' + new Date().getTime(),
          serviceId: c._id,
          name: c.name,
          orno: form.orno.value,
          rate: parseFloat(form.price.value),
          service: c
        });

        setItemData(x);
      }
    );
  };

  const deleteItem = (data, callback) => {
    const x = [...itemData];
    const result = _.filter(x, (d) => d._id !== data._id);
    setItemData(result);
  };

  const editItem = (data, callback) => {
    console.log(data);
    context.modal.show(
      {
        title: 'Update Services',
        form: {
          _id: {
            type: 'hidden',
            value: data._id
          },
          name: {
            type: 'select',
            options: servicesList,
            default: data.service._id,
            label: 'Service Name',
            required: true
          },

          orno: {
            type: 'text',
            label: 'OR/s',
            default: '',
            required: false,
            value: data.orno
          },
          price: {
            type: 'number',
            label: 'Price',
            required: true,
            value: data.original_rate || data.rate
          }
        },
        buttonText: 'Save Services',
        url: '/api/newInvoice/dummy',
        method: 'POST'
      },
      (form, res) => {
        const x = [...itemData];
        console.log(x);

        const result = _.map(x, (d) => {
          if (d._id === form._id.value) {
            const c = services.data.find((d) => d._id === form.name.value);
            d.serviceId = c._id;
            d.name = c.name;
            d.rate = form.price.value;
            d.orno = form.orno.value ? form.orno.value : '';
            d.service = c;
          }

          return d;
        });

        setItemData(result);
      }
    );
  };

  const getSubTotal = () => {
    const x = [...itemData];
    return _.sumBy(x, (o) => {
      return parseFloat(o.rate);
    });
  };

  const updateInvoice = async () => {
    try {
      const total = getSubTotal();
      const data = {
        customer: selectedCustomer,
        items: itemData,
        customerId: selectedCustomer._id,
        customerName: selectedCustomer.name,
        customerAddress: selectedCustomer.address,
        invoiceNumber: invoiceNo,
        invoiceDate: invoiceDate,
        consignee: consignee,
        subject: purpose,
        subTotal: total,
        totalAmount: total,
        commodity,
        bol,
        noc,
        ata,
        vessel,
        container,
        lessPaymet: 0
      };

      await axios({
        url: `/api/invoices/${params.get('id')}`,
        method: 'patch',
        data
      });

      context.notification.show('Invoice Saved', 'success', true);
      setLoading(false);
      navigate('/invoices');
    } catch (err) {
      context.handleError(err);
    }
  };

  const saveInvoice = async () => {
    try {
      setLoading(true);
      const total = getSubTotal();

      const data = {
        customer: selectedCustomer,
        items: itemData,
        customerId: selectedCustomer._id,
        customerName: selectedCustomer.name,
        customerAddress: selectedCustomer.address,
        invoiceNumber: invoiceNo,
        consignee: consignee,
        subject: purpose,
        subTotal: total,
        totalAmount: total,
        commodity,
        bol,
        noc,
        ata,
        vessel,
        container,
        lessPaymet: 0
      };

      await axios({
        url: '/api/invoices',
        method: 'post',
        data
      });

      context.notification.show('Invoice Saved', 'success', true);
      setLoading(false);
      navigate('/invoices');
    } catch (err) {
      context.handleError(err);
    }
  };

  const printMe = async () => {
    setLoading(true);
    const total = getSubTotal();
    const data = {
      customer: selectedCustomer,
      items: itemData,
      customerId: selectedCustomer._id,
      customerName: selectedCustomer.name,
      invoiceDate: invoiceDate,
      customerAddress: selectedCustomer.address,
      invoiceNumber: invoiceNo,
      consignee: consignee,
      subject: purpose,
      subTotal: total,
      totalAmount: total,
      commodity,
      bol,
      noc,
      ata,
      vessel,
      container,
      lessPaymet: 0
    };

    await printBilling(data);
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Card>
            <Label text='Customer' />
            {/* <TextInput value={selectedCustomer.name} /> */}
            <Select
              options={customersList}
              default={selectedCustomer._id}
              onChange={handleSelectChange}
            />
            {_.isEmpty(selectedCustomer) ? null : (
              <p>Address: {selectedCustomer.address}</p>
            )}
          </Card>

          <Card>
            <Label text='Invoice #' />
            <TextInput value={invoiceNo} onChange={handleInvoiceNoChange} />

            <Label text='Invoice Date' />
            <TextInput value={invoiceDate} onChange={handleInvoiceDateChange} />
          </Card>

          <Grid cols='2'>
            <Card>
              <Label text='Consignee' />
              <TextInput value={consignee} onChange={handleConsignee} />

              <Label text='Commodity' />
              <TextInput value={commodity} onChange={handleCommodity} />

              <Label text='ATA' />
              <TextInput value={ata} onChange={handleAta} />

              <Label text='Container Nos' />
              <TextInput value={container} onChange={handleContainer} />
            </Card>

            <Card>
              <Label text='Subject/Purpose' />
              <TextInput value={purpose} onChange={handlePurpose} />

              <Label text='Bill of Lading' />
              <TextInput value={bol} onChange={handleBol} />

              <Label text='No. Of Cntrs' />
              <TextInput value={noc} onChange={handleNoc} />

              <Label text='Vessel' />
              <TextInput value={vessel} onChange={handleVessel} />
            </Card>
          </Grid>

          <Card>
            <TitleRow title='Services'>
              <Button text='Add Services' action={addItem} />
            </TitleRow>

            {/* <Table
              className='restrict-width'
              data={itemData}
              show={['name', 'orno', 'rate']}
              actions={{ delete: deleteItem, edit: editItem }}
            /> */}

            <DataTable columns={columns} data={itemData} />
          </Card>

          <br />

          <PriceTotal subTotal={getSubTotal()} />
          <br />
          <Button
            fullWidth
            color='blue'
            text='Save Invoice'
            action={saveInvoice}
            loading={loading}
          />
          <br />
          {/* <Pdftest invoice={invoiceData} /> */}
          {/* <Button
            fullWidth
            color='green'
            text='Print Invoice'
            action={printMe}
            loading={loading}
          /> */}
        </div>
      )}
    </>
  );
}
