import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  useAPI,
  Card,
  Button,
  Table,
  Animate,
  TitleRow,
  useNavigate,
  ViewContext
} from 'components/lib';
import printBilling from 'common/printBilling';

export function Invoices (props) {
  const [state, setState] = useState({});
  const [items, setItems] = useState([]);
  const list = useAPI('/api/invoices');
  const navigate = useNavigate();
  const context = useContext(ViewContext);

  useEffect(() => {
    console.log('triggeer');
    if (list?.data?.length) {
      console.log(list.data);
      setItems(list.data);
    }
  }, [list.data]);

  const clickGoto = (data, callback) => {
    navigate({
      pathname: '/editInvoice',
      search: '?id=' + data._id
    });
  };

  const printPdf = async (data, callback) => {
    printBilling(data);
  };

  const copyTemplate = (data, callback) => {
    context.modal.show(
      {
        title: 'Copy Invoice Template',
        form: {
          id: {
            type: 'hidden',
            value: data._id
          }
        },
        buttonText: 'Copy Invoice',
        text: 'Are you sure you want to copy this invoice?',
        url: '/api/newInvoice/dummy',
        method: 'POST'
      },
      () => {
        // context.notification.show(data.name + ' was deleted', 'success', true);
        navigate({
          pathname: '/copyInvoice',
          search: '?id=' + data._id
        });
      }
    );
  };

  const deleteItem = (data, callback) => {
    context.modal.show(
      {
        title: 'Delete Invoice',
        form: {
          id: {
            type: 'hidden',
            value: data._id
          }
        },
        buttonText: 'Delete Invoice',
        text: `Are you sure you want to delete ${data.name}?`,
        url: '/api/invoices/' + data._id,
        method: 'DELETE'
      },
      (res) => {
        window.location = '/invoices';
      }
    );
  };

  return (
    <>
      {/* render view data */}
      <Animate>
        <TitleRow title='Manage Invoices'>
          <Button
            goto='/newInvoice'
            text='New Billing'
            className='inline-block'
            big
          />
        </TitleRow>

        <Card>
          <Table
            search
            className='restrict-width'
            data={items}
            loading={list?.loading}
            show={[
              'customerName',
              'consignee',
              'invoiceNumber',
              'date_created'
            ]}
            actions={{
              copy: copyTemplate,
              edit: clickGoto,
              download: printPdf,
              delete: deleteItem
            }}
          />
        </Card>
      </Animate>
    </>
  );
}
