import { CopyInvoice } from 'views/copyInvoice';
import { EditInvoice } from 'views/editInvoice';
import { PdfView } from 'views/pdfView';
import { NewInvoice } from 'views/newInvoice';
import { Invoices } from 'views/invoices';
import { Services } from 'views/services';
import { Customers } from 'views/customers';
import { Dashboard } from 'views/dashboard/dashboard';
import { Help } from 'views/dashboard/help';
import { OnboardingView } from 'views/onboarding/onboarding';

const Routes = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard'
  },
  {
    path: '/welcome',
    view: OnboardingView,
    layout: 'onboarding',
    permission: 'user',
    title: 'Welcome'
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help'
  },
  {
    path: '/customers',
    view: Customers,
    layout: 'app',
    permission: 'user',
    title: 'Customers'
  },
  {
    path: '/services',
    view: Services,
    layout: 'app',
    permission: 'user',
    title: 'Services'
  },
  {
    path: '/invoices',
    view: Invoices,
    layout: 'app',
    permission: 'user',
    title: 'Invoices'
  },
  {
    path: '/newInvoice',
    view: NewInvoice,
    layout: 'app',
    permission: 'user',
    title: 'NewInvoice'
  },
  {
    path: '/pdfView',
    view: PdfView,
    layout: 'printpreview',
    permission: 'user',
    title: 'PdfView'
  },
  {
    path: '/editInvoice',
    view: EditInvoice,
    layout: 'app',
    permission: 'user',
    title: 'EditInvoice'
  },
  {
    path: '/copyInvoice',
    view: CopyInvoice,
    layout: 'app',
    permission: 'user',
    title: 'CopyInvoice'
  }
];

export default Routes;
