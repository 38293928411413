/***
 *
 *   HOMEPAGE
 *   Homepage template with features, testimonials and CTA
 *
 **********/

import React, { Fragment } from 'react';
import { Row, Button, Hero, Features, Testimonial } from 'components/lib';
import ImgDashboard from './images/dashboard.png';
import ImgKyleGawley from './images/kyle-gawley.jpg';

export function Home (props) {
  return (
    <>
      <Hero
        title='Build a Node.js & React SaaS app at warp speed'
        tagline='Gravity is the leading Node.js & React SaaS boilerplate. Get all features you need in a single install.'
        image={ImgDashboard}
      />
    </>
  );
}
