/***
*
*   APP LAYOUT
*   Main application layout containing the navigation
*   and header (title, secondary nav and signed in user)
*
*   PROPS
*   title: title of the view
*
**********/

import React, { Fragment, useContext } from 'react';
import { AuthContext, AppNav, Header, User } from 'components/lib';
import Style from './app.module.scss';

export function AppLayout (props) {
  // context & style
  const context = useContext(AuthContext);

  return (
    <>
      <AppNav
        items={[
          { label: 'Dashboard', icon: 'activity', link: '/dashboard' },
          { label: 'Customers', icon: 'user', link: '/customers' },
          { label: 'Services', icon: 'chrome', link: '/services' },
          { label: 'Billing', icon: 'bookmark', link: '/invoices' },
          { label: 'Account', icon: 'key', link: '/account' },
          { label: 'Help', icon: 'help-circle', link: '/help' },
          { label: 'Sign Out', icon: 'log-out', action: context.signout }
        ]}
      />

      <main className={Style.app}>

        <Header title={props.title}>
          <User />
        </Header>

        <props.children {...props.data} />

      </main>
    </>
  );
}
