import React, { useEffect } from 'react';
import { Button } from 'components/lib';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import { Buffer } from 'buffer';

function headRows () {
  return [
    {
      particulars: 'Particulars',
      orno: 'OR No./S',
      amount: '            Amount'
    }
  ];
}

function bodyRows (items) {
  const rowCount = items.length;
  const body = [];
  for (let j = 1; j <= rowCount; j++) {
    body.push({
      id: j,
      particulars: items[j - 1].name,
      amount: formatCurrency(items[j - 1].rate, 'PHP'),
      orno: items[j - 1].orno
    });
  }
  return body;
}

const formatCurrency = (val, currency) => {
  const currencyVal = val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  switch (currency) {
    case 'USD':
      val = `$ ${currencyVal}`;
      break;
    case 'EUR':
      val = `€ ${currencyVal}`;
      break;
    case 'PHP':
      val = `PHP ${currencyVal}`;
      break;
    default:
      throw new Error('Unknown currency format');
  }
  return currencyVal;
};

async function getBase64 (url) {
  return axios
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Allow-Origin': '*'
      }
    })
    .then((response) =>
      Buffer.from(response.data, 'binary').toString('base64')
    );
}

const printBilling = async (invoiceData) => {
  const doc = new jsPDF();
  const totalPagesExp = '{total_pages_count_string}';
  // const base64Img = await getBase64('https://kajsystem-ab0ef.web.app//kaj.jpg');
  const base64Img = await getBase64(
    'https://billing.kajcustomsbrokerage.com/kaj.jpg'
  );
  // const base64Img = await getBase64('http://localhost:3000/kaj.jpg');

  const docWidth = doc.internal.pageSize.width;

  doc.autoTable({
    head: headRows(),
    body: bodyRows(invoiceData.items),
    columnStyles: {
      amount: {
        halign: 'right'
      }
    },
    didDrawPage: function (data) {
      // Header
      doc.setFontSize(15);
      doc.setTextColor(40);
      if (base64Img) {
        doc.addImage(base64Img, 'JPEG', 15, 12, 40, 30);
      }
      doc.text(docWidth - 10, 23, 'KAJ Customs Brokerage', 'right');
      doc.setFontSize(8);
      doc.text(
        docWidth - 10,
        28,
        'Block 7 Lot 18 Mutual Homes Subdivision',
        'right'
      );
      doc.text(docWidth - 10, 33, 'Davao City, Philippines 8000', 'right');
      doc.text(docWidth - 10, 38, 'Contact# : 09173160004', 'right');
      doc.text(
        docWidth - 10,
        43,
        'Email: kajcustomsbrokerage@gmail.com',
        'right'
      );

      // doc.line(15, 47, docWidth - 10, 47);

      doc.setFontSize(15);
      doc.text(15, 53, 'BILL TO : ', 'left');
      doc.setFontSize(12);
      doc.text(15, 60, invoiceData.customerName);
      doc.setFontSize(10);
      doc.text(15, 68, invoiceData.customerAddress);
      // doc.text(15, 73, 'Highway, Mandaue City 6014 Philippines');

      doc.text(15, 80, `Invoice No: ${invoiceData.invoiceNumber}`);
      doc.text(
        docWidth - 15,
        80,
        `Invoice Date: ${invoiceData.invoiceDate}`,
        'right'
      );
      doc.line(15, 85, docWidth - 10, 85);

      doc.setFontSize(10);
      doc.text(15, 92, `Consignee: ${invoiceData.consignee}`, 'left');
      doc.text(
        docWidth - 15,
        92,
        `Bill of Lading: ${invoiceData.bol}`,
        'right'
      );
      doc.text(15, 97, `Commodity: ${invoiceData.commodity}`, 'left');
      doc.text(docWidth - 15, 97, `No. Of Cntrs: ${invoiceData.noc}`, 'right');
      doc.text(15, 102, `ATA: ${invoiceData.ata}`, 'left');
      doc.text(docWidth - 15, 102, `Vessel: ${invoiceData.vessel}`, 'right');
      doc.text(15, 107, `Container Nos: ${invoiceData.container}`, 'left');

      doc.line(15, 112, docWidth - 10, 112);

      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();

      doc.line(docWidth - 100, pageHeight - 75, docWidth - 10, pageHeight - 75);

      doc.setFontSize(11);
      doc.text(docWidth - 75, pageHeight - 65, 'Amount', 'right');
      doc.text(
        docWidth - 18,
        pageHeight - 65,
        `${formatCurrency(invoiceData.subTotal, 'PHP')}`,
        'right'
      );

      doc.setFontSize(11);
      doc.text(docWidth - 75, pageHeight - 58, 'Less(Advance)', 'right');
      doc.text(
        docWidth - 18,
        pageHeight - 58,
        `${formatCurrency(0, 'PHP')}`,
        'right'
      );

      doc.line(docWidth - 100, pageHeight - 55, docWidth - 10, pageHeight - 55);

      doc.setFontSize(15);
      doc.text(docWidth - 75, pageHeight - 45, 'Total Amount', 'right');
      doc.text(
        docWidth - 18,
        pageHeight - 45,
        `PHP ${formatCurrency(invoiceData.totalAmount, 'PHP')}`,
        'right'
      );

      doc.setFontSize(10);
      doc.text(
        15,
        pageHeight - 30,
        '*Please Contact us within 7 days should there be any descrepancies \n*Above Charges are not subject to 12% VAT and Expanded Withholding Tax.',
        'left'
      );

      // // Footer
      // let str = 'Page ' + doc.internal.getNumberOfPages();
      // // Total page number plugin only available in jspdf v1.0+
      // if (typeof doc.putTotalPages === 'function') {
      //   str = str + ' of ' + totalPagesExp;
      // }
      // doc.setFontSize(8);

      // // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      // const pageSize = doc.internal.pageSize;
      // const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
      // doc.text(str, data.settings.margin.left, pageHeight - 10);
    },
    margin: { top: 120 }
  });

  // Total page number plugin only available in jspdf v1.0+
  if (typeof doc.putTotalPages === 'function') {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(`${invoiceData.invoiceNumber}.pdf`);
};

export default printBilling;
