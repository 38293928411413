import React, { Fragment, useState, useEffect } from 'react';
import { Loader, Card, Grid, Label } from 'components/lib';
import Style from './priceTotal.tailwind.js';

export function PriceTotal (props) {
  const [state, setState] = useState();

  useEffect(() => {

  }, []);

  const formatCurrency = (val, currency) => {
    const currencyVal = val.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    switch (currency) {
      case 'USD':
        val = `$ ${currencyVal}`;
        break;
      case 'EUR':
        val = `€ ${currencyVal}`;
        break;
      case 'PHP':
        val = `₱ ${currencyVal}`;
        break;
      default:
        throw new Error('Unknown currency format');
    }
    return val;
  };

  return (
    <div className={Style.priceTotal}>
      <Grid cols='2'>
        <div />
        <div>
          <Card>
            <Grid cols='2'>
              <Label text='Sub Total' />
              <Label className={Style.textright} text={formatCurrency(props.subTotal, 'PHP')} />
            </Grid>
            <Grid cols='2'>
              <Label text='(Payments)' />
              <Label className={Style.textright} text={formatCurrency(-0, 'PHP')} />
            </Grid>
            <Grid cols='2'>
              <Label className={Style.textxl} text='Total (PHP)' />
              <Label className={Style.textrightxl} text={formatCurrency(props.subTotal, 'PHP')} />
            </Grid>
          </Card>
        </div>
      </Grid>

    </div>
  );
}
