/***
*
*   ONBOARDING LAYOUT
*   Simple layout to focus on user onboarding actions
*
**********/

import React from 'react';
import Style from './printpreview.tailwind.js';

export function PrintpreviewLayout (props) {
  return (
    <main className={Style.onboarding}>

      <props.children {...props.data} />

    </main>
  );
}
